import * as React from "react"
import { Icon } from "reflexjs"
import Block from "/src/components/header-002"
import BlockCommits from "/src/components/call-to-action-001"

export default function Club() {
  return (
    <>
    <Block
      minH="500|0"
      branding={{
        name: "A1",
      }}
      links={[
        {
          title: "SOFTBALL HIGHLIGHTS",
          href: "/softball-highlights",
        },
        {
          title: "SCHEDULE",
          href: "/softball-schedule",
        },
        {
          title: "ACCOMPLISHMENTS",
          href: "/accomplishments-awards",
        },
        {
          title: "2ND SPORT WRESTLING",
          href: "/wrestling-highlights",
        },
      ]}
      buttonLink={{
        title: "NCAA ID# 2009918132",
        href: "#",
      }}
    />
    <BlockCommits
      subheading="Club Coach"
      heading="Brian Georges"
      text="I selected Alannah as an undersized 13 year old, to my 18U Gold team. She has consistently been in the top 3 in hitting, since moving up to 18U Gold and competing against the top teams and Junior Colleges in the country. She has 86 hits in 231 at bats over the course of 105 Games played. Over the span of her 105 games at the 18U Gold level, Alannah has slashed .372/.449/.903. Always a good hitter, I chose Alannah for her tenacity on Defense, her grit, and superior softball IQ. That and she is a phenomenal human being from a phenomenal family. Alannah now hits close to .450, fields at a .950 clip and leads our team with 10 Wins this summer in the circle. Her changeup, drop curve, and off speed drop combination are very deceptive. Small in stature, Alannah is all muscle and all heart. Brian Georges - Head Coach Arizona Hotshots 18u Gold Team"
      buttons={
        <a variant="button.accent.lg" href="/high-school-coach-comments" mt="6">
          Read H.S. Coach Comments <Icon name="arrow-right" ml="2" size="4" />
        </a>
      }
    />
    </>
  )
}